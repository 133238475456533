<template>
  <div v-if="title && extrasellItems.length" class="title">{{ title }}</div>
  <productlist
    categoryid="orderPage"
    :mode="mode"
    :count="count"
    :showaddtocart="true"
    :addToCartLabel="addToCartLabel"
    :postMountedProducts="extrasellItems"
    :cartMode="true"
  />
  <seo pagetype="order-page" />
  <tracking />
  <loader />
  <debug />
</template>
<script>
import CartService from "@/services/cart";
import AbTests from "@/services/abTests";
import PaymentMethodService from "@/services/payment/paymentMethodService";
import productlist from "@/components/productlist/ProductList.vue";
import tracking from "@/components/Tracking.vue";
import seo from "@/components/Seo.vue";
import loader from "@/components/Loader.vue";
import { useCartStore } from "@/store/cart";
import { useDraftStore } from "@/store/draft";
import Debug from "@/components/Debug.vue";

export default {
  name: "OrderPageProcess",
  components: {
    Debug,
    productlist,
    tracking,
    seo,
    loader,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    mode: {
      type: String,
      default: "orderpage-list",
    },
    count: {
      type: Number,
      default: 1,
    },
    addToCartLabel: {
      type: String,
    },
  },
  data() {
    return {
      cart: null,
      productDetails: null,
      notCartFilteredExtrasellItems: [],
      extrasellItems: [],
    };
  },
  computed: {
    lastCartUpdate() {
      return useCartStore().lastUpdate;
    },
    selectedPaymentMethod() {
      return useDraftStore().selectedPaymentMethod;
    },
  },
  watch: {
    lastCartUpdate: {
      handler(newValue, oldValue) {
        if (!window?.globalConfig?.domainId) {
          return;
        }

        this.cart = this.getCart();
      },
    },
    selectedPaymentMethod: {
      handler(newValue, oldValue) {
        // If selected payment method has changed :
        // Find the same choice but matching the correct payment method
        for (const cartItem of this.cart.items) {
          // if (
          //   cartItem.choice.paymentMethod &&
          //   cartItem.choice.paymentMethod !== newValue
          // ) {
          //   const matchingChoice =
          //     PaymentMethodService.findChoiceMatchingPaymentMethod(
          //       cartItem.choice.id,
          //       newValue,
          //     );
          //   if (!matchingChoice) {
          //     console.warn("Could not find choice with this paymentMethod");
          //   } else {
          //     cartItem.choice = matchingChoice;
          //   }
          // }
        }

        // Update list of item that can be added to cart
        for (const extrasellItem of this.notCartFilteredExtrasellItems) {
          // if (
          //   extrasellItem.choice.paymentMethod &&
          //   extrasellItem.choice.paymentMethod !== newValue
          // ) {
          //   const matchingChoice =
          //     PaymentMethodService.findChoiceMatchingPaymentMethod(
          //       extrasellItem.choice.id,
          //       newValue,
          //     );
          //   if (!matchingChoice) {
          //     console.warn("Could not find choice with this paymentMethod");
          //   } else {
          //     extrasellItem.choice = matchingChoice;
          //   }
          // }
        }

        this.filterExtrasellItems();
      },
    },
    cart: {
      handler(newValue, oldValue) {
        this.filterExtrasellItems();
      },
      deep: true,
    },
  },
  mounted() {
    this.cart = this.getCart();
    this.productDetails = window.productDetails;

    const urlParams = new URLSearchParams(window.location.search);
    const abGroups = this.productDetails.initialChoices;

    if (abGroups.length === 1) {
      useCartStore().cart.hideDelete = true; // hide delete button on cart items
    }

    this.notCartFilteredExtrasellItems = [];
    let electedChoices = [];
    //const defaultPaymentMethod = this.getDefaultPaymentMethod();
    for (const abGroup of abGroups) {
      if (
        urlParams.has("addChoices") &&
        urlParams.get("addChoices") !== "true"
      ) {
        const choicesToAdd = urlParams.get("addChoices").split(",");
        for (const choiceToAddId of choicesToAdd) {
          let electedChoice = this.productDetails.choices.find(
            (choice) => choice.id === choiceToAddId,
          );

          // if (defaultPaymentMethod !== electedChoice.paymentMethod) {
          //   const matchingChoice =
          //     PaymentMethodService.findChoiceMatchingPaymentMethod(
          //       electedChoice.id,
          //       defaultPaymentMethod,
          //     );
          //   if (matchingChoice) {
          //     electedChoice = matchingChoice;
          //   }
          // }

          electedChoices.push(electedChoice);
        }
      } else {
        const electedAbChoice = AbTests.electItemOfGroup(abGroup);
        if (electedAbChoice) {
          let electedChoice = this.productDetails.choices.find(
            (choice) => choice.id === electedAbChoice.id,
          );

          // if (defaultPaymentMethod !== electedChoice.paymentMethod) {
          //   electedChoice = PaymentMethodService.findChoiceMatchingPaymentMethod(
          //     electedChoice.id,
          //     defaultPaymentMethod,
          //   );
          // }

          electedChoices.push(electedChoice);
        }
      }
    }

    if (electedChoices.length) {
      for (const electedChoice of electedChoices) {
        if (!electedChoice) {
          continue;
        }
        const productToAdd = Object.assign({}, this.productDetails);
        productToAdd.qtyIncr = 1;
        productToAdd.choice = electedChoice;
        const itemAlreadyInCart = this.cart.items.find(
          (item) => item.id === this.productDetails.id,
        );

        if (
          (urlParams.has("addChoices") || electedChoices.length === 1) &&
          !itemAlreadyInCart
        ) {
          CartService.addToCart(productToAdd);
        } else if (!urlParams.has("addChoices")) {
          productToAdd.choice.default = 1;
          productToAdd.choice.show = 1;
          productToAdd.choices = [];
          productToAdd.choices.push(productToAdd.choice);
          this.notCartFilteredExtrasellItems.push(productToAdd);
        }
      }
    }

    this.filterExtrasellItems();
  },
  methods: {
    getCart() {
      return useCartStore().cart;
    },
    getDefaultPaymentMethod() {
      return PaymentMethodService.getDefaultPaymentMethod();
    },
    filterExtrasellItems() {
      const alreadyInCartIds = [];
      for (const cartItem of this.cart.items) {
        alreadyInCartIds.push(cartItem.choice.id);
      }

      const inCartParents = [];
      if (this.productDetails.upsells) {
        const upsellParentMapping = {};
        for (const upsellItem of this.productDetails.upsells) {
          for (const upsellGroup of upsellItem.children) {
            for (const upsellChoice of upsellGroup) {
              if (!upsellParentMapping[upsellChoice.id]) {
                upsellParentMapping[upsellChoice.id] = [upsellItem.parentId];
              } else {
                upsellParentMapping[upsellChoice.id].push(upsellItem.parentId);
              }
            }
          }
        }
        for (const alreadyInCartId of alreadyInCartIds) {
          const upsellParents = upsellParentMapping[alreadyInCartId];
          if (upsellParents) {
            inCartParents.push(...upsellParents);
          }
        }
      }

      this.extrasellItems = this.notCartFilteredExtrasellItems.filter(
        (item) => {
          const notAlreadyInCart = !alreadyInCartIds.includes(item.choice.id);
          const choiceHasNotBeenUpselled = !inCartParents.includes(
            item.choice.id,
          );
          return notAlreadyInCart && choiceHasNotBeenUpselled;
        },
      );
    },
  },
};
</script>
