<template></template>

<script>
import { useTrackingStore } from "@/store/tracking";
import SessionService from "@/services/session";
import { constants } from "@/constants";
import Utils from "@/services/utils";
import { useOrderHistoryStore } from "@/store/orderHistory";
import sha256 from "sha256";

export default {
  name: "Tracking",
  data() {
    return {
      gtmIdentifier: "",
    };
  },
  mounted() {
    let url = window.location.origin + window.location.pathname;

    if (
      useTrackingStore().urlParams.testLevel !== undefined &&
      useTrackingStore().urlParams.testLevel !== null &&
      useTrackingStore().urlParams.testLevel !== "null" &&
      useTrackingStore().urlParams.testLevel !== "" &&
      useTrackingStore().urlParams.testLevel !== "vanguard_only" &&
      useTrackingStore().urlParams.testLevel !== "orion_only" &&
      useTrackingStore().urlParams.testLevel !== "salesforce_only" &&
      useTrackingStore().urlParams.testLevel !== "TEST"
    ) {
      window.location.href = `${window.location.origin}${window.globalConfig.errorPage}`;
      return;
    }
    if (useTrackingStore().urlParams.testLevel) {
      url += `?testLevel=${useTrackingStore().urlParams.testLevel}`; // show test level in url
    }

    //window.history.pushState({}, document.title, url); // removing query params from url

    if (window.globalConfig.gtmIdentifier) {
      this.insertHeadGtm();

      if (window.location.pathname === "/confirmation") {
        this.insertConfirmationDataLayer();
      }

      this.insertBodyGtm();
    }

    this.generateSession();

    this.transferFromCookieToLocalstorage();
  },
  methods: {
    insertHeadGtm() {
      const scriptTag = document.createElement("script");
      scriptTag.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${window.globalConfig.gtmIdentifier}');`;
      document.body.appendChild(document.createComment("Google Tag Manager"));
      document.head.appendChild(scriptTag);
      document.body.appendChild(
        document.createComment("End Google Tag Manager"),
      );
    },
    generateSession() {
      SessionService.generateNewSession(this.getPageData());
    },
    getPageData() {
      if (window.category) {
        return {
          type: "category",
          id: window.category.id,
        };
      }
      if (window.productDetails) {
        return {
          type: "product",
          id: window.productDetails.id,
        };
      }
      if (window.location.pathname === "/") {
        return {
          type: "home",
          id: null,
        };
      }
    },
    insertBodyGtm() {
      const noScriptTag = document.createElement("noscript");
      noScriptTag.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${window.globalConfig.gtmIdentifier}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(
        document.createComment("Google Tag Manager (noscript)"),
      );
      document.body.appendChild(noScriptTag);
      document.body.appendChild(
        document.createComment("End Google Tag Manager (noscript)"),
      );
    },
    insertConfirmationDataLayer() {
      if (
        !useOrderHistoryStore().orderHistory ||
        useOrderHistoryStore().orderHistory.length < 1 ||
        useOrderHistoryStore().orderHistory.slice(-1)[0] === undefined
      ) {
        return;
      }

      const lastHistoryItem = useOrderHistoryStore().orderHistory.slice(-1)[0];
      const order = lastHistoryItem.order;
      const cart = lastHistoryItem.cart;
      const gtmItems = [];

      for (const index in cart.items) {
        const item = cart.items[index];
        gtmItems.push({
          item_id: item.code,
          item_name: item.name,
          index: parseInt(index),
          item_brand: window.globalConfig.brandName,
          item_category2: item.choice.bcl ? "BCL" : "NBCL",
          price: parseFloat((item.choice.unitPrice / 100).toFixed(2)),
          quantity: item.qty,
          item_variant: "X" + item.choice.numberOfUnit,
        });
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        pageLanguage: window.globalConfig.lang
          ? window.globalConfig.lang.toUpperCase()
          : "FR",
        payment_type: order.paymentType,
        hemail: sha256(order.billingEmail.toLowerCase()),
        trackingCode: useTrackingStore().urlParams.trackingCode
          ? useTrackingStore().urlParams.trackingCode
          : "XMISSING",
        externalPartnerId: useTrackingStore().urlParams.externalPartnerId
          ? useTrackingStore().urlParams.externalPartnerId
          : null,
        event: "purchase",
        transaction_id: order.externalOrderId,
        tax: 0,
        shipping: parseFloat((cart.shippingPrice / 100).toFixed(2)),
        value: parseFloat((cart.subtotal / 100).toFixed(2)),
        items: gtmItems,
      });
    },
    transferFromCookieToLocalstorage() {
      if (useTrackingStore().urlParams.sfId || !Utils.getCookie("atlasData"))
        return;

      let sfId = {};

      try {
        sfId = JSON.parse(Utils.getCookie("atlasData"))?.sfId;
      } catch (e) {
        console.warn("atlasData is not json valid cookie");
        return;
      }

      Utils.deleteCookie("atlasData");
      useTrackingStore().mergeTrackingData({ sfId });
    },
  },
};
</script>
