<template>
  <TransitionGroup name="pagination-effect-list">
    <div
      class="product-item"
      v-for="product in products"
      v-bind:data-product-id="product.id"
      :key="product.id"
    >
      <div class="product-item-info row align-items-center">
        <ProductListImage
          :product="product"
          is-from-list
          :isProductListFromParent="isProductListFromParent"
        />
        <div class="product-item-details col-sm-4">
          <h3 class="product-item-name">
            <ChoiceVanguardLabel :choice="getDefaultChoice(product)" />
          </h3>
          <div class="small-text">
            <ChoiceVanguardDescription :choice="getDefaultChoice(product)" />
          </div>
          <div class="listing-long-name">
            {{ product.longName }}
          </div>
          <div class="free-label">
            <ChoiceFreeLabel :choice="getDefaultChoice(product)" />
          </div>
          <a class="know-more" :href="product.url">
            <span>{{ $t("labels.more") }}</span>
          </a>
        </div>
        <template v-if="getDefaultChoice(product)">
          <Price
            :product="getDefaultChoice(product)"
            class="price-container col-sm-3"
          />
        </template>
        <template v-if="showaddtocart && getDefaultChoice(product)">
          <AddToCart
            :item="fillProductWithChoice(product)"
            :btnlabel="addToCartLabel"
            class="col-sm-3"
          />
        </template>
        <a v-else :href="product.url" class="col-sm-2">
          <span class="goto-product">{{ $t("labels.more") }}</span>
        </a>
      </div>
    </div>
  </TransitionGroup>
</template>

<script>
import AddToCart from "@/components/AddToCart.vue";
import Price from "@/components/productlist/subcomponents/Price.vue";
import Utils from "@/services/utils";
import Ratings from "@/components/product/Ratings.vue";
import ProductListImage from "@/components/productlist/subcomponents/Image.vue";
import ChoiceVanguardLabel from "@/components/choice/VanguardLabel.vue";
import ChoiceFreeLabel from "@/components/choice/FreeLabel.vue";
import ChoiceVanguardDescription from "@/components/choice/VanguardDescription.vue";

export default {
  name: "OrderPageList",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    categoryid: {
      type: String,
      default: "",
    },
    showaddtocart: {
      type: Boolean,
      default: true,
    },
    addToCartLabel: {
      type: String,
    },
    showprocessorder: {
      type: Boolean,
      default: false,
    },
    processordertext: {
      type: String,
    },
    getDefaultChoice: {
      type: Function,
      required: true,
    },
    fillProductWithChoice: {
      type: Function,
      required: true,
    },
    isProductListFromParent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ChoiceVanguardDescription,
    ChoiceFreeLabel,
    ChoiceVanguardLabel,
    ProductListImage,
    Ratings,
    AddToCart,
    Price,
  },
  methods: {
    displayDuration(duration, startingPrice = false) {
      return Utils.displayDuration(duration, this.$i18n.locale, startingPrice);
    },
  },
};
</script>
