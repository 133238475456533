<template>
    <div
        v-if="showModal"
        class="modal fade show d-block"
        tabindex="-1"
        role="dialog"
        style="background-color: rgba(0, 0, 0, 0.5);"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <slot>
                        <!-- Contenu par défaut si aucun slot n'est fourni -->
                        <p>{{ defaultContent }}</p>
                    </slot>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal">
                        {{ $t("modal.close") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: 'Modal Title',
        },
        defaultContent: {
            type: String,
            default: 'This is the default modal content.',
        },
        closeButtonText: {
            type: String,
            default: 'Close',
        },
    },
    emits: ['close'],
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
/* Optionnel : Vous pouvez ajouter des styles supplémentaires ici si nécessaire */
</style>
